.footer {
    background-color: #222; 
    color: white;
    text-align: center;
    padding: 10px 20px; 
    position: fixed;
    bottom: 0;
    left: 50%; 
    transform: translateX(-50%); 
    width: 100%; 
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.5); 
    font-size: 14px; 
  }
  
  .footer a {
    color: #4CAF50; 
    text-decoration: none;
    margin: 0 5px;
  }
  
  .footer a:hover {
    text-decoration: underline; 
  }
  