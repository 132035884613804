.faq-container {
    width: 100%; 
    padding: 20px;
    background-color: #222; 
    color: #f4f4f4; 
}

.faq-page-title {
    font-size: 2.5em; 
    color: #ffcc00; 
    text-align: center; 
    margin-bottom: 30px; 
    padding: 10px; 
    border-bottom: 2px solid #444; 
}

.faq-section {
    width: 100%; 
    margin: 0 auto; 
    padding: 30px; 
    background-color: #333; 
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); 
}

.faq-section h3 {
    font-size: 2em; 
    color: #ffcc00; 
    text-align: center;
    margin-bottom: 20px; 
}

.faq-content {
    max-width: 1000px; 
    margin: 0 auto; 
    text-align: justify; 
    line-height: 1.8; 
}

.faq-item {
    margin-bottom: 25px; 
    padding-bottom: 10px; 
    border-bottom: 1px solid #444;
}

.faq-item h4 {
    font-size: 1.5em; 
    color: #ffcc00; 
    margin-bottom: 10px; 
    text-align: center; 
}

.faq-item p {
    font-size: 1.1em; 
    color: #f4f4f4; 
    margin: 0 auto;
    padding: 0 10px; 
}

@media (max-width: 768px) {
    .faq-container {
        padding: 10px;
    }

    .faq-section {
        padding: 20px;
    }

    .faq-content {
        max-width: 90%; 
    }

    .faq-item h4 {
        font-size: 1.3em; 
    }

    .faq-item p {
        font-size: 1em; 
    }
}

.cta-section {
    background-color: #ffcc00;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  
  .cta-section h3 {
    font-size: 2em;
    color: #222;
    margin-bottom: 30px;
  }
  
  .cta-section p {
    font-size: 1.2em;
    color: #222;
  }
  
  .cta-section .cta-button {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.5em;
    background-color: #222;
    color: #ffcc00;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .cta-section .cta-button:hover {
    background-color: #333;
  }