.header {
    background-color: #444;
    padding: 5x;
    width: 100%;  
    margin: 0 auto; 
    box-sizing: border-box; 
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    z-index: 750; 
    margin-top: 0; 
  }
  
  body {
    margin: 0;
    padding-top: 30px; 
  }
    
  .nav-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
  
  .nav-links a {
    text-decoration: none;
    color: white;
  }
  