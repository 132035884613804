.home-container {
  width: 100%;
  padding: 20px;
  background-color: #222; 
  color: #f4f4f4; 
  margin-bottom: 25px; 
  box-sizing: border-box;
}

.home-page-title {
  font-size: 2.5em;
  color: #ffcc00; 
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid #444; 
}

.grid-section {
  display: grid;
  grid-template-areas:
    "frontend backend data"
    "alerts alerts alerts"
    "self-hosting diagram diagram";
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  margin-bottom: 40px;
  padding: 10px; 
  box-sizing: border-box; 
  grid-auto-rows: 1fr; 
}

.grid-box {
  background-color: #333; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.grid-box h3 {
  color: #ffcc00; 
  margin-bottom: 10px;
}

.grid-box p {
  font-size: 1em;
  line-height: 1.6;
}

.grid-box.frontend {
  grid-area: frontend;
}

.grid-box.backend {
  grid-area: backend;
}

.grid-box.data {
  grid-area: data;
}

.grid-box.alerts {
  grid-area: alerts;
  background-color: #ffcc00; 
  color: #222; 
}

.grid-box.alerts h3 {
  color: #222; 
}

.grid-box.self-hosting {
  grid-area: self-hosting;
}

.grid-box.diagram {
  grid-area: diagram; 
  min-height: 400px; 
  background-color: #222; 
  padding: 10px;
  border-radius: 8px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.diagram-image {
  width: 100%; 
  height: auto; 
  object-fit: contain; 
  max-width: 100%; 
}

.blog-section {
  margin-top: 40px;
  margin-bottom: 40px; 
  padding: 20px;
  background-color: #333; 
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box; 
}

.blog-section-title {
  font-size: 1.8em;
  color: #ffcc00; 
  text-align: center;
  margin-bottom: 20px;
}

.blog-previews {
  display: flex;
  justify-content: space-between; 
  gap: 20px;
  flex-wrap: wrap; 
}

.blog-preview {
  background-color: #444; 
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); 
  flex: 1 1 calc(50% - 20px); 
  max-width: calc(50% - 20px);
  box-sizing: border-box;
}

.blog-preview h3 {
  font-size: 1.5em;
  color: #ffcc00; 
  margin-bottom: 10px;
}

.blog-preview p {
  font-size: 1em;
  color: #f4f4f4; 
  margin-bottom: 15px;
}

.blog-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: #222; 
  background-color: #ffcc00; 
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.blog-button:hover {
  background-color: #e6b800; 
}

.cta-section {
  background-color: #ffcc00;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 35px;
}

.cta-section h3 {
  font-size: 2em;
  color: #222;
  margin-bottom: 10px;
}

.cta-section p {
  font-size: 1.2em;
  color: #222;
}

.cta-section button,
.cta-section a {
  padding: 15px 30px;
  font-size: 1.5em;
  background-color: #222;
  color: #ffcc00;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
}

.cta-section button:hover,
.cta-section a:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .grid-section {
    grid-template-areas:
      "frontend"
      "backend"
      "data"
      "alerts"
      "self-hosting"
      "diagram";
    grid-template-columns: 1fr; 
  }

  .home-page-title {
    font-size: 2em;
  }

  .blog-previews {
    flex-direction: column; 
  }

  .blog-preview {
    max-width: 100%; 
    flex: 1 1 100%;
  }

  .grid-box.self-hosting, .grid-box.diagram {
    min-height: auto;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #222;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain; 
  border-radius: 8px;
}
