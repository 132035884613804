.faq-container {
  width: 100%;
  padding: 20px;
  background-color: #222;
  color: #f4f4f4;
}

.faq-page-title {
  font-size: 2.5em;
  color: #ffcc00;
  text-align: center;
  margin-bottom: 30px;
  padding: 10px;
  border-bottom: 2px solid #444;
}

.faq-section {
  background-color: #333;
  padding: 40px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
}

.content-block {
  margin-bottom: 30px;
  padding: 20px;
  border-left: 4px solid #ffcc00; 
  background-color: #3a3a3a; 
  border-radius: 5px; 
}

.content-block p {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.contact-form {
  max-width: 600px;
  margin: 30px auto;
  background-color: #444;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.6);
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #ffcc00; 
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #222;
  color: #f4f4f4;
  font-size: 1em;
}

.contact-form select {
  cursor: pointer;
  appearance: none;
}

.contact-form button {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1.3em;
  font-weight: bold;
  background-color: #ffcc00;
  color: #222;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-form button:hover {
  background-color: #e6b800;
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .faq-page-title {
    font-size: 2em;
  }

  .faq-section {
    padding: 20px;
  }

  .contact-form {
    padding: 20px;
  }

  .contact-form button {
    font-size: 1.2em;
  }
}

.centered-text {
  text-align: center; 
  margin: 0 auto 20px; 
  font-size: 1.1em; 
  line-height: 1.6; 
}
