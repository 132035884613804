.homepage-container {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  background-color: #222; 
  color: #f4f4f4; 
  font-family: Arial, sans-serif; 
  box-sizing: border-box; 
  padding-top: 40px; 
  padding-bottom: 80px; 
}

.section-title h2 {
  font-size: 2.5em;
  color: #ffcc00; 
  margin-bottom: 30px;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #444; 
}

.loading-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); 
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  padding: 10px;
}

.loading-overlay h2 {
  font-size: 2em; 
  margin: 0;
}

.metrics-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;
  justify-items: center; 
  margin-bottom: 40px;
}

.metric-card {
  background-color: #333; 
  color: #f4f4f4; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 90%;
}

.metric-card h3 {
  font-size: 1.2em;
  color: #ffcc00; 
  margin-bottom: 10px;
}

.metric-card p {
  font-size: 1.1em;
  color: #f4f4f4; 
}

.collapsible-section {
  margin: 20px auto;
  padding: 15px;
  width: 90%;
  background-color: #444; 
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); 
}

.collapsible-button {
  width: 100%;
  padding: 15px;
  background-color: #333;
  color: #ffcc00; 
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.collapsible-button:hover {
  background-color: #555;
}

.chart-container {
  margin-top: 15px;
  padding: 20px;
  background-color: #222; 
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .metrics-box {
    grid-template-columns: 1fr; 
  }

  .metric-card {
    width: 100%;
  }

  .section-title h2 {
    font-size: 2em; 
  }

  .homepage-container {
    padding-top: 120px;
  }

  .collapsible-button {
    font-size: 1em;
  }
}
