.blog-container {
    max-width: 1000px;
    margin: 75px auto; 
    padding: 30px;
    background-color: #333; 
    border-radius: 10px; 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6); 
    color: #f4f4f4; 
    line-height: 1.8;
    overflow-wrap: break-word; 
  }
  
  .blog-container h1 {
    font-size: 3em;
    color: #ffcc00; 
    margin-bottom: 25px;
    border-bottom: 3px solid #444;
    padding-bottom: 15px;
    text-align: center;
  }
  
  .blog-container h2 {
    font-size: 1.8em;
    color: #ffcc00;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .blog-container p {
    font-size: 1.25em;
    margin-bottom: 25px;
    color: #e6e6e6;
    text-align: left;
  }
  
  .blog-container ul {
    list-style: none; 
    padding-left: 0; 
    margin: 20px 0;
  }
  
  .blog-container ul li {
    font-size: 1.25em;
    margin-bottom: 15px;
    text-indent: -1em; 
    padding-left: 1em;
    color: #e6e6e6; 
  }
  
  .blog-container ul li strong {
    color: #ffcc00; 
  }
  
  .blog-container hr {
    margin: 30px 0;
    border: 0;
    border-top: 2px solid #444;
  }
  
  .blog-container a {
    color: #ffcc00;
    text-decoration: none;
    font-weight: bold;
  }
  
  .blog-container a:hover {
    text-decoration: underline;
  }
  
  .blog-container img {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  body {
    background-color: #f9f9f9; 
    margin: 0;
    padding: 0;
  }
  
  .blog-container {
    margin-top: 100px; 
    margin-bottom: 100px; 
  }
  
  header {
    margin-bottom: 25px; 
  }
  
  @media (max-width: 768px) {
    .blog-container {
      padding: 20px;
    }
  
    .blog-container h1 {
      font-size: 2.5em;
    }
  
    .blog-container p {
      font-size: 1.1em;
    }
  }
  